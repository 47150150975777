import Input from '../../components/input';
import React from 'react';
import Button from '../../components/button';
import { showErrorNotification, showSuccessNotification } from '../../lib/notificationManager';
import miniLogo from '../../assets/image/miniLogo.png';
import { saveStorageData, StorageKeys } from '../../lib/storageManager';
import styled from 'styled-components';
import Colour from '../../lib/colour';
import { authContext } from '../../components/authWrapper';
import PropTypes from 'prop-types';
import { routes } from '../../components/navigation/routes';
import { useMutation } from '@apollo/client';
import { LOGIN } from '../../lib/graphQl/mutation';

const FormWrapper = styled.div`
  background-color: ${Colour.White};
  border-radius: 5px;
  padding: 48px;
  min-width: 450px;
`;

const Logo = styled.img`
  width: 60px;
  height: 60px;
`;

const LoginText = styled.span`
  display: block;
  font-size: 32px;
  font-weight: 700;
  margin-top: 32px;
  margin-bottom: 32px;
`;

const LastInputWrapper = styled.div`
  margin-top: 16px;
  margin-bottom: 32px;
`;

export default function Login(props) {
  const authContextManager = React.useContext(authContext);

  const [loggingIn, setLoggingIn] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [emailError, setEmailError] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [passwordError, setPasswordError] = React.useState('');

  const [doLogin] = useMutation(LOGIN);

  async function onSubmit(event) {
    event.preventDefault();
    setLoggingIn(true);
    try {
      let shouldSubmit = true;
      if (!email.trim()) {
        shouldSubmit = false;
        setEmailError('Email must be filled');
      } else if (emailError.trim()) {
        setEmailError('');
      }
      if (!password.trim()) {
        shouldSubmit = false;
        setEmailError('Password must be filled');
      } else if (passwordError.trim()) {
        setPasswordError('');
      }

      if (shouldSubmit) {
        const response = await doLogin({
          variables: {
            email,
            password,
          },
        });
        if (response.data?.loginUser?.status === 'success') {
          await saveStorageData(StorageKeys.AuthToken, response.data.loginUser.token);
          showSuccessNotification(response.data?.loginUser?.message);
          authContextManager.onUserChange(response.data.loginUser.data);
        }

        if (response.data?.loginUser?.status === 'failed') {
          showErrorNotification(response.data?.loginUser?.message);
        }
      }
    } catch (e) {
      showErrorNotification(e.message);
    } finally {
      setLoggingIn(false);
    }
  }

  function onForgotPassword() {
    props.history.push(routes.forgotPassword);
  }

  return (
    <div id="auth-wrapper">
      <FormWrapper>
        <Logo src={miniLogo} alt="logo" />
        <LoginText>Login</LoginText>
        <form onSubmit={onSubmit} autoComplete="on">
          <Input label="E-mail" value={email} onChange={setEmail} error={emailError} autoComplete="email" required />
          <LastInputWrapper>
            <Input
              label="Password"
              rightLabel="Forgot Password?"
              onRightLabelClick={onForgotPassword}
              value={password}
              onChange={setPassword}
              type="password"
              error={passwordError}
              autoComplete="current-password"
              required
            />
          </LastInputWrapper>
          <Button type="submit" loading={loggingIn} fullWidth>
            Login
          </Button>
        </form>
      </FormWrapper>
    </div>
  );
}

Login.propTypes = {
  history: PropTypes.object,
};
