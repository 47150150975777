import React, { useState, useEffect } from "react";
import Table from "../table";
import Select from "../select";
import TextContent from "../../components/textContent";
import Colour from "../../lib/colour";
import { GET_LOG_BY_PLAYLIST, GET_PLAYLIST } from "../../lib/graphQl/query";
import { useQuery } from "@apollo/client";
import PageTitle from "../pageTitle";

export default function Logs() {
    const [playlist, setPlaylist] = useState("");
    const [pageLimit, setPageLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [offset, setOffset] = useState(0);
    
    // Fetch playlists
    const { data: playlistsData, loading: playlistsLoading } = useQuery(GET_PLAYLIST, {
        variables: { offset: 0, limit: 0 },
    });

    // Fetch logs for selected playlist
    const { data, loading } = useQuery(GET_LOG_BY_PLAYLIST, {
        variables: { playlistId: playlist, limit: pageLimit, offset: offset },
        skip: !playlist, // Skip query if no playlist is selected
    });

    function updatePageLimit() {
        if (pageLimit === 10) {
            setPageLimit(20);
        } else if (pageLimit === 20) {
            setPageLimit(50);
        } else if (pageLimit === 50) {
            setPageLimit(100);
        } else {
            setPageLimit(10);
        }
    }

    function returnPaginationToDefault() {
        setOffset(0);
        setPage(1);
    }

    useEffect(() => {
        returnPaginationToDefault();
    }, [playlist]);

    function onPrevPage() {
        setPage((prevPage) => Math.max(prevPage - 1, 1)); // Prevent going below page 1
    }
    
    function onNextPage() {
        setPage((prevPage) => prevPage + 1); // Assuming you handle data availability for next page elsewhere
    }

    const columns = [
        {
            title: "Song Name",
            width: 150,
            align: "center",
            dataIndex: "songName",
            render: (songName) => (
                <TextContent fontSize={14} fontWeight="500" colour={Colour.BlackText}>
                    {songName}
                </TextContent>
            ),
        },
        {
            title: "Action",
            width: 150,
            align: "center",
            dataIndex: "action",
            render: (action) => (
                <TextContent fontSize={14} fontWeight="500" colour={Colour.BlackText}>
                    {action}
                </TextContent>
            ),
        },
        {
            title: "Time",
            width: 150,
            align: "center",
            dataIndex: "time",
            render: (time) => (
                <TextContent fontSize={14} fontWeight="500" colour={Colour.BlackText}>
                    {time}
                </TextContent>
            ),
        },
    ];

    // Prepare table data
    const tableData = Array.isArray(data?.getLogEntriesByPlaylistId?.data)
        ? data.getLogEntriesByPlaylistId.data.map((entry) => ({
            songName: entry.songName,
            action: entry.action,
            time: new Date(parseInt(entry.createdAt)).toLocaleString(),
        }))
        : [];

    const playlistOptions = [
        { value: "", label: "Select a playlist" },
        ...(playlistsData?.listPlaylist?.data?.map((p) => ({
            value: p.id,
            label: p.title,
        })) || []),
    ];

    const playlistSelect = ({ value }) => {
        setPlaylist(value);
    };

    return (
        <>
            <PageTitle title="PLAYLIST LOGS" />
            {playlistsLoading ? (
                <p>Loading playlists...</p>
            ) : (
                <Select
                    value={playlist}
                    onSelect={playlistSelect}
                    options={playlistOptions}
                    placeholder="Select a playlist"
                />
            )}
            <br />
            <Table
            columns={columns}
            data={tableData}
            loading={loading}
            limit={pageLimit}
            showPagination 
            updatePageLimit={updatePageLimit}
            total={tableData.length|| 1}
            onPrevPage={onPrevPage}
            onNextPage={onNextPage}
            page={page}
            /> 
        </>
    );
}
