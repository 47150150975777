import { authContext } from "../authWrapper";
import { Route, Switch, Redirect } from "react-router-dom";
import Login from "../../views/login";
import Layout from "../layout";
import React from "react";
import { routes } from "./routes";
import ForgotPassword from "../../views/forgotPassword";
import Dashboard from "../../views/dashboard";
import Users from "../../views/users";
import Admin from "../../views/admin";
import Playlist from "../../views/playlist";
import Songs from "../../views/songs";
import Games from "../../views/games";
import LeaderBoard from "../../views/leaderBoard";
import ViewGame from "../../views/viewGame"
import NewSong from "../../views/newSong";
import ViewPlaylist from "../../views/viewPlaylist";
import Bundles from "../../views/bundles";
import Transactions from "../../views/transactions";
import Settings from "../../views/settingsView";
import TrialView from "../../views/settingsView/TrialView";
import Notification from '../notification';
import Notifications from '../../views/notification';
import Logs from "../logs";

export default function Navigation() {
  const authorizationContext = React.useContext(authContext);

  if (!authorizationContext.user) {
    return (
      <Switch>
        <Route exact path={routes.home} component={Login} />
        <Route exact path={routes.login} component={Login} />
        <Route exact path={routes.forgotPassword} component={ForgotPassword} />
        <Redirect to={routes.login} />
      </Switch>
    );
  }

  return (
    <Layout>
      <Notification user={authorizationContext.user} />
      <Switch>
        <Route exact path={routes.dashboard} component={Dashboard} />
        <Route exact path={routes.users} component={Users} />
        <Route exact path={routes.viewPlaylist} component={ViewPlaylist} />
        <Route exact path={routes.playlist} component={Playlist} />
        <Route exact path={routes.newSong} component={NewSong} />
        <Route exact path={routes.songs} component={Songs} />
        <Route exact path={routes.games} component={Games} />
        <Route exact path={routes.leaderBoard} component={LeaderBoard} />
        <Route exact path={routes.viewGame} component={ViewGame} />
        <Route exact path={routes.admin} component={Admin} />
        <Route exact path={routes.bundles} component={Bundles} />
        <Route exact path={routes.transactions} component={Transactions} />
        <Route exact path={routes.settings} component={Settings} />
        <Route exact path={routes.settingsFreeTrial} component={TrialView} />
        <Route exact path={routes.notification} component={Notifications} />
        <Route exact path={routes.logs} component={Logs} />
        <Redirect from={routes.home} to={routes.dashboard} />
        <Redirect to={routes.dashboard} />
      </Switch>
    </Layout>
  );
}
