import React from 'react';
import Input from '../input';
import Button from '../button';
import Modal from '../modal';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Colour from '../../lib/colour';
import TextContent from '../textContent';
import Table from '../table';
import { useQuery } from '@apollo/client';
import Checkbox from '../checkbox';
import { GET_PLAYLIST } from '../../lib/graphQl/query';
import { useMutation } from '@apollo/client';
import { CREATE_LOG } from '../../lib/graphQl/mutation';
import { showErrorNotification, showSuccessNotification } from '../../lib/notificationManager';

const Container = styled.div`
  display: flex;

  > div:first-child {
    flex-basis: 40%;

    > div {
      margin-bottom: 32px;
    }
  }

  > div:last-child {
    flex-basis: 60%;
    margin-left: 64px;
  }
`;

const Form = styled.form`
  > button {
    margin-top: 32px;
  }
  > div {
    margin-bottom: 24px;
  }
`;

const SongButtonWrapper = styled.div`
  margin-top: 32px;
  margin-bottom: 16px;
`;

const Error = styled.span`
  margin-top: 4px;
  font-size: 14px;
  color: ${Colour.Error};
`;
const pageLimit = 50;
export default function NewSongModal(props) {
  const [title, setTitle] = React.useState('');
  const [titleError, setTitleError] = React.useState('');
  const [artist, setArtist] = React.useState('');
  const [artistError, setArtistError] = React.useState('');
  const [album, setAlbum] = React.useState('');
  const [albumError, setAlbumError] = React.useState('');
  const [referenceId, setReferenceId] = React.useState('');
  const [referenceIdError, setReferenceIdError] = React.useState('');
  const [file, setFile] = React.useState(null);
  const [fileError, setFileError] = React.useState('');
  const fileInputRef = React.createRef();
  const [page, setPage] = React.useState(1);
  const [offset, setOffset] = React.useState(0);
  const [selectedPlaylistError, setSelectedPlaylistError] = React.useState('');
  const [createLog] = useMutation(CREATE_LOG);

  const { data, loading } = useQuery(GET_PLAYLIST, {
    variables: {
      offset,
      limit: pageLimit,
    },
  });
  const columns = [
    {
      title: 'PLAYLISTS',
      dataIndex: 'id',
      render: (id, row) => {
        const isChecked = props.selectedPlaylist.includes(row.id);
        return <Checkbox label={row.title} isChecked={isChecked} onChange={() => onSelectedRow(row)} />;
      },
    },
  ];

  React.useEffect(() => {
    if (props.editingSong?.title) {
      setTitle(props.editingSong.title);
      setArtist(props.editingSong.artist);
      setAlbum(props.editingSong.album);
      setFile(props.editingSong.file);
      setReferenceId(props.editingSong.isrCode);
      props.setSelectedPlaylist(props.editingSong.featuredPlaylists);
    }
  }, [props.editingSong]);

  async function onSubmit(event) {
    event.preventDefault();
    let shouldSubmit = true;

    if (!title.trim()) {
      shouldSubmit = false;
      setTitleError('Title must be filled');
    } else if (titleError.trim()) {
      setTitleError('');
    }
    if (!artist.trim()) {
      shouldSubmit = false;
      setArtistError('Artist must be filled');
    } else if (artistError.trim()) {
      setArtistError('');
    }
    if (!album.trim()) {
      shouldSubmit = false;
      setAlbumError('Album must be filled');
    } else if (albumError.trim()) {
      setAlbumError('');
    }

    if (shouldSubmit && props.onSubmit) {
      const initialPlaylists = props.editingSong?.featuredPlaylists || [];
      const newSelections = props.selectedPlaylist.filter((id) => !initialPlaylists.includes(id));
      const removedSelections = initialPlaylists.filter((id) => !props.selectedPlaylist.includes(id));

      props.onSubmit({
        id: props.editingSong?.id,
        featuredPlaylists: props.selectedPlaylist,
        file: file ? file : undefined,
        title,
        artist,
        album,
        isrCode: referenceId,
      });

      if (newSelections.length > 0 || removedSelections.length > 0) {
        const logs = [];
        if (newSelections.length > 0) {
          logs.push({
            playlistId: newSelections,
            songId: props.editingSong?.id,
            songName: title,
            action: 'added',
          });
        }

        if (removedSelections.length > 0) {
          logs.push({
            playlistId: removedSelections,
            songId: props.editingSong?.id,
            songName: title,
            action: 'removed',
          });
        }

        try {
          for (const log of logs) {
            await createLog({ variables: log });
          }
          showSuccessNotification('Logged entered succesfully', 'Log');
        } catch (error) {
          showErrorNotification(error.message);
        }
      } else {
        console.log('No changes to playlists for logging');
      }
    }
  }

  function onPrevPage() {
    setOffset((page - 2) * pageLimit);
    setPage((prevPage) => prevPage - 1);
  }

  function onNextPage() {
    setOffset(page * pageLimit);
    setPage((prevPage) => prevPage + 1);
  }

  function onSelectedRow(row) {
    const isSelected = props.selectedPlaylist.includes(row.id);
    if (isSelected) {
      props.setSelectedPlaylist((prev) => prev.filter((id) => id !== row.id));
    } else {
      props.setSelectedPlaylist((prev) => [...prev, row.id]);
    }
    setSelectedPlaylistError(null);
  }

  function onFileSelect(event) {
    if (event?.currentTarget?.files.length) {
      setFile(event.currentTarget.files[0]);
    }
  }

  function onInitiateFileSelect() {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }

  return (
    <Modal isActive={props.isActive} onCancel={props.onClose} title="Edit Song">
      <Form onSubmit={onSubmit} autoComplete="on">
        <SongButtonWrapper>
          <Button
            variant={props.isEdit || file ? 'success' : 'subdue'}
            onClick={props.isEdit ? undefined : onInitiateFileSelect}
            fullWidth
          >
            <>
              <TextContent fontSize={18} colour={Colour.White}>
                {props.isEdit
                  ? `Uploaded - ${props.editingSong?.title}.mp3`
                  : file
                  ? `Upload - ${file.name}`
                  : 'Upload Song'}
              </TextContent>
              <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={onFileSelect} />
            </>
          </Button>
          {fileError && <Error>{fileError}</Error>}
        </SongButtonWrapper>
        <Container>
          <div>
            <Input label="Song Title" value={title} onChange={setTitle} error={titleError} required />
            <Input label="Artist" value={artist} onChange={setArtist} error={artistError} required />
            <Input label="Album" value={album} onChange={setAlbum} error={albumError} required />
            <Input
              label="Reference Number"
              value={referenceId}
              onChange={setReferenceId}
              error={referenceIdError}
              placeholder={referenceId ? referenceId : 'N/A'}
              required
            />
          </div>
          <div>
            {selectedPlaylistError && <Error>{selectedPlaylistError}</Error>}
            <Table
              total={data?.listPlaylist?.totalCount || 1}
              columns={columns}
              data={data?.listPlaylist?.data || []}
              onNextPage={onNextPage}
              onPrevPage={onPrevPage}
              loading={loading}
              page={page}
              limit={pageLimit}
              onSelectedRowChange={onSelectedRow}
              showPagination
            />
          </div>
        </Container>
        <Button type="submit" fullWidth loading={props.isProcessing} disabled={props.isProcessing}>
          <TextContent fontSize={18} colour={Colour.White}>
            Save Changes
          </TextContent>
        </Button>
      </Form>
    </Modal>
  );
}

NewSongModal.propTypes = {
  isActive: PropTypes.bool.isRequired,
  isEdit: PropTypes.bool,
  isProcessing: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  editingSong: PropTypes.object,
  selectedPlaylist: PropTypes.array.isRequired,
  setSelectedPlaylist: PropTypes.func,
};
